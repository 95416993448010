<template>
  <div class="filter">
    <a-form-model
      :model="form"
      :colon="false"
      ref="form"
      :class="`filter-form ${lang}`"
      layout="inline"
      labelAlign="right"
    >
      <a-row class="filter-form-inner" :gutter="[16, 16]">
        <a-col :span="8" v-for="i in formList" :key="i.key">
          <div v-if="i.type === 'slot'">
            <slot v-if="i.slotName" :name="i.slotName"></slot>
            <slot v-else></slot>
          </div>
          <template v-else>
            <a-form-model-item :label="i.label">
              <a-input
                size="large"
                v-model="form[i.key]"
                v-if="i.type === 'input'"
                :placeholder="$t(i.placeholder) || $t(i.label)"
                allowClear
                v-support="`${form[i.key]}`"
              />
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                size="large"
                v-if="i.type === 'select'"
                v-model="form[i.key]"
                :placeholder="$t(i.placeholder) || $t(i.label)"
                allowClear
              >
                <a-select-option
                  v-for="op in i.options"
                  :key="op.value"
                  :value="op.value"
                >
                  {{ op.label }}
                </a-select-option>
              </a-select>
              <a-range-picker
                size="large"
                :separator="$t('space')"
                v-if="i.type === 'timeRange'"
                v-model="form[i.key]"
                v-support="`${form[i.key]}`"
                :inputReadOnly="true"
              />
              <a-date-picker
                size="large"
                :show-time="i.showTime === void 0 ? true : i.showTime"
                allowClear
                v-if="i.type === 'time'"
                v-model="form[i.key]"
                class="width"
                :inputReadOnly="true"
                v-support="`${form[i.key]}`"
                :placeholder="i.placeholder ? $t(i.placeholder) : ''"
              />
            </a-form-model-item>
          </template>
        </a-col>
      </a-row>
      <div class="filter-btn">
        <a-button @click="reset" class="reset-btn" :disabled="loading">
          {{ $t("button.reset") }}
        </a-button>
        <a-button
          type="primary"
          @click="filter"
          class="search-btn"
          :disabled="loading"
        >
          {{ $t("button.filter") }}
        </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { langMixin } from "@/mixins";

export default {
  name: "ListFilter",
  props: {
    // 表单配置项 label标题 key变量名 type元素类型
    formList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String
    },
    formConfig: {
      type: Object,
      default: () => {}
    },
    // 加载中状态
    loading: {
      type: Boolean
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  mixins: [langMixin],
  data() {
    return {
      formDataInner: {}
    };
  },
  computed: {
    form: {
      get() {
        return this.formData || this.formDataInner;
      },
      set(newValue) {
        this.$emit("update:formData", newValue);
        this.formDataInner = { ...newValue };
      }
    }
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.form = {};
      this.$emit("reset", this.form);
    },
    filter() {
      this.$emit("filter", this.form);
    },
    setForm(values) {
      this.form = values;
    }
  }
};
</script>

<style lang="less" scoped>
.filter-form {
  padding: @ec-gutter20 0;

  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    .ant-form-item-label {
      width: 30%;
      text-align: right;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }

  &.en {
    /deep/.ant-form-item {
      .ant-form-item-label {
        width: 40%;
      }
    }
  }
}
.reset-btn {
  width: 146px;
  height: 42px;
  background: #b2c0df;
  border-radius: 8px;
  color: #fff;
  &:hover,
  &:focus {
    border: 1px solid #b2c0df;
  }
  margin-right: @ec-gutter20;
}
.search-btn {
  width: 146px;
  height: 42px;
  border-radius: 8px;
}

.filter-btn {
  text-align: center;
  margin-top: @ec-gutter20 * 2;
}
/deep/.ant-calendar-range-picker-separator {
  vertical-align: middle;
}
.filter-form-inner {
  display: flex;
  flex-wrap: wrap;
}
.ant-calendar-picker {
  width: 100%;
}
</style>
